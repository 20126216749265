import React, { useEffect, useRef, useState } from 'react';
import useEventListner from '../../../../controllers/useEventListner.controller';
import Button from '../../button';
import styles from './styles.module.css';

const EditTaskPopup = (props) => {
  const {
    handleClose = () => {},
    deleteTask = () => {}, resumeTask = () => {}, pauseTask = () => {}, mode,
    index, clickEvent = {}, completeTask = () => {}, restartTask = () => {},
  } = props;
  const menuRef = useRef();

  const handleMouseClick = (e = {}) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (path && !path.includes(menuRef.current)) {
      handleClose();
      e.stopPropagation();
    }
  };

  useEventListner({ onClick: handleMouseClick, dependencies: [] });
  const [text, setText] = useState('');

  useEffect(() => {
    if (mode === 'runningTask') {
      setText('Pause');
    } else if (mode === 'pausedTask') {
      setText('Resume');
    }
  }, [mode, setText]);

  const handlePauseResume = () => {
    if (mode === 'runningTask') {
      pauseTask(index);
    } else if (mode === 'pausedTask') {
      resumeTask(index);
    }
    handleClose();
  };

  /* - Calculating te position of popup based on where the click happened */
  let popUpTop = clickEvent.clientY;
  let popUpLeft = clickEvent.clientX;
  let popUpRight;
  let popUpBottom;

  if (window.innerWidth < popUpLeft + (16 * 12)) {
    popUpRight = window.innerWidth - popUpLeft;
    popUpLeft = undefined;
  }
  if (window.innerHeight < popUpTop + (16 * 8)) {
    popUpBottom = window.innerHeight - popUpTop;
    popUpTop = undefined;
  }

  const popupStyle = {
    left: popUpLeft,
    top: popUpTop,
    bottom: popUpBottom,
    right: popUpRight,
  };
  /* -------------------------------------------------------------------- */

  return (
    <div className={styles.editTaskWrapper}>
      <div ref={menuRef} className={styles.menuWrapper} style={popupStyle}>
        {mode !== 'completedTask' && <Button text={text} onClick={handlePauseResume} classNames={[styles.button]} />}
        <Button text={mode === 'completedTask' ? 'Restart task' : 'Complete Task'} classNames={[styles.button]} onClick={mode === 'completedTask' ? () => restartTask(index) : () => completeTask(index)} />
        <Button text="Delete" onClick={() => deleteTask(index)} classNames={[styles.button]} type="delete" />
      </div>
    </div>
  );
};

export default EditTaskPopup;
