import { useEffect } from 'react';

const useEventListner = (props) => {
  const {
    onClick = () => {},
    dependencies = [],
    event = 'click',
    target = window,
  } = props;
  useEffect(() => {
    target.addEventListener(event, onClick);
    return () => {
      target.removeEventListener(event, onClick);
    };
  }, dependencies);
};

export default useEventListner;
