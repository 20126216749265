import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../constants/routes.json';
import { usePageViewTracker } from '../../../controllers/analytics.controller';
import PortfolioPopup from './PortfolioPopup';
import styles from './styles.module.css';

const Portfolio = () => {
  const BrandingIllustration = `${process.env.PUBLIC_URL}/assets/images/branding_vector_illustration.svg`;

  const brandingLogoSrc = `${process.env.PUBLIC_URL}/assets/images/branding_logo.svg`;

  const tagline = 'A minimalistic tool to track your time mindfully!';
  const goToApp = 'Start Tracking for FREE!';
  const privacyPolicy = 'Privacy Policy';
  const about = 'About';
  const tAndC = 'Terms and Conditions';

  usePageViewTracker('Portfolio Page');

  // const Features = [
  //   {
  //     title: 'Minimalistic',
  //     icon: 'minimalistic_icon',
  //     description: 'Less is more! Just the features you absolutely need. Nothing messy!',
  //   },
  //   {
  //     title: 'Cloud Sync',
  //     icon: 'cloud_sync_icon',
  //     description: 'All your data is backed-up & stored with Google Drive securely.',
  //   },
  //   {
  //     title: 'Multi-Device',
  //     icon: 'multi_device_icon',
  //     description: 'Available on all your Mobile, Tablet Desktop devices.',
  //   },
  // ];

  return (
    <div className={styles.portfolioWrapper}>
      <div className={styles.brandingSection}>
        <div className={styles.brandingLeftSection}>
          <img className={styles.brandLogo} src={brandingLogoSrc} alt="branding logo" />
          <h2 className={styles.tagline}>{tagline}</h2>
          <Link className={styles.goToAppButton} to={Routes.timeTracker}>
            {goToApp}
          </Link>
        </div>
        <div className={styles.brandingRightSection}>
          <img className={styles.brandIllustration} src={BrandingIllustration} alt="branding logo" />
        </div>
      </div>
      {/* <div className={styles.featuresSection}>
        {Features.map((f) => (
          <div className={styles.featureCard}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/${f.icon}.svg`} alt="feature_icon" />
            <h3>{f.title}</h3>
            <p>{f.description}</p>
          </div>
        ))}
      </div> */}
      <div className={styles.footer}>
        <div className={styles.pages}>
          <Link to={Routes.about}>
            {about}
          </Link>
          <Link to={Routes.privacyPolicy}>
            {privacyPolicy}
          </Link>
          <Link to={Routes.termsAndConditions}>
            {tAndC}
          </Link>
        </div>
        <p className={styles.credit}>
          Made with ❤️ by
          {' '}
          <a style={{ color: '#fa7313' }} href="https://helixatom.com">HelixAtom</a>
        </p>
      </div>
      <PortfolioPopup />
    </div>
  );
};

export default Portfolio;
