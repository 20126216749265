import React, { useEffect, useRef, useState } from 'react';
import useEventListner from '../../../../controllers/useEventListner.controller';
import { formatToHHMMSS } from '../../../../utils/commonUtils';
import { fetchFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '../../../../utils/storageUtils';
import { notificationPauseDeplay } from '../../../page/settings';
import EditTaskPopup from '../editTaskPopup';
import styles from './styles.module.css';

const ActiveTask = (props) => {
  const {
    data: {
      name, mode, updates = [], id,
    }, deleteTask, resumeTask, pauseTask, index, updateCountedTime,
    completeTask, restartTask,
  } = props;
  const counter = useRef(0);
  const visibility = useRef(true);
  const [time, setTime] = useState('--:--:--');
  const [showPopup, setShowPopup] = useState(false);

  const initialCounterSetting = () => {
    let c = 0;
    const isPaused = updates.length % 2 === 0;
    if (isPaused) {
      // Task is paused
      updates.forEach((x, i) => {
        if (i % 2 !== 0) {
          c += ((updates[i].paused || updates[i].completed) - (updates[i - 1].resumed || updates[i - 1].restarted));
        }
      });
    } else {
      // Task is running
      updates.forEach((x, i) => {
        if (i < (updates.length - 1)) {
          if (i % 2 !== 0) {
            c += ((updates[i].paused || updates[i].completed) - (updates[i - 1].resumed || updates[i - 1].restarted));
          }
        } else {
          const autoPause = fetchFromLocalStorage({ id: 'nextAutoPause' });
          if (autoPause && autoPause < new Date().getTime()) {
            c += (autoPause - (updates[i].resumed || updates[i].restarted));
            pauseTask(id, autoPause);
            removeFromLocalStorage({ id: 'nextAutoPause' });
          } else {
            c += (new Date().getTime() - (updates[i].resumed || updates[i].restarted));
          }
        }
      });
    }
    counter.current = c;
  };
  useEffect(initialCounterSetting, []);

  const handleVisibilityChange = (e) => {
    if (!e.target.hidden) {
      visibility.current = true;
      // if (mode === 'runningTask') {
      //   removeFromLocalStorage({ id: 'nextAutoPause' });
      //   // Setting time for next background-auto pause
      // }
      const currentTime = new Date().getTime();
      const lastActiveTime = fetchFromLocalStorage({ id: 'lastActiveTime' });
      if (currentTime > lastActiveTime + 70000) {
        initialCounterSetting();
      }
      window.location.href = window.location.pathname;
    } else {
      visibility.current = false;
      // when going to background
    }
  };
  useEventListner({ event: 'visibilitychange', onClick: handleVisibilityChange, target: document });

  // window.onblur = () => { console.log('its gone to background'); };
  // window.onfocus = () => { console.log('its ACTIVE now'); };
  const notificationTime = fetchFromLocalStorage({ id: 'activityTimer' }) || 5;

  useEffect(() => {
    const setEverySecond = () => {
      if (counter.current > 0) setTime(formatToHHMMSS(counter.current));
      if (mode === 'runningTask') {
        counter.current += 1000;
        const autoPause = fetchFromLocalStorage({ id: 'nextAutoPause' });
        if (autoPause) {
          if (new Date().getTime() > autoPause) {
            // pause this task
            pauseTask(id);
            removeFromLocalStorage({ id: 'nextAutoPause' });
          }
        }
        if (visibility.current) {
          saveToLocalStorage({ id: 'nextAutoPause', data: new Date().getTime() + (notificationTime * 60 * 1000) + (notificationPauseDeplay * 60 * 1000) });
        }
      }
      if (counter.current > 0) setTime(formatToHHMMSS(counter.current));
    };
    document.addEventListener('everySecond', setEverySecond);
    return () => {
      document.removeEventListener('everySecond', setEverySecond);
    };
  }, [mode]);

  const enablePopup = (e) => {
    if (!showPopup) {
      setShowPopup(e);
    }
  };

  return (
    <div className={styles.activeTaskWrapper} role="none" onClick={enablePopup}>
      <div className={styles.taskName}>{name}</div>
      <div className={styles.time}>{time}</div>
      {showPopup ? (
        <EditTaskPopup
          index={index}
          mode={mode}
          clickEvent={showPopup}
          handleClose={() => setShowPopup(false)}
          deleteTask={deleteTask}
          resumeTask={resumeTask}
          pauseTask={(i) => pauseTask(i)}
          completeTask={(i) => completeTask(i)}
          restartTask={restartTask}
          updateCountedTime={updateCountedTime}
        />
      ) : null}
    </div>
  );
};

export default ActiveTask;
