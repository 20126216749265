import { useEffect } from 'react';
import { addNotification, clearAllNotification } from '../../../utils/customEventUtils';

export default ({ filterTasks, tasks }) => {
  const dispatchNotifications = () => {
    const filteredTasks = filterTasks({ mode: 'runningTask', tasks });
    Object.keys(filteredTasks).forEach((t, i) => {
      if (i === (Object.keys(filteredTasks).length - 1)) { // only dispatching first item
        addNotification(filteredTasks[t]);
      }
    });
  };

  const handleVisibilityChange = (e) => {
    const visible = !e.target.hidden;
    if (!visible) {
      dispatchNotifications();
    } else {
      clearAllNotification();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    clearAllNotification();
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      dispatchNotifications();
    };
  }, [filterTasks]);
};
