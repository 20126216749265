export default {
  light: {
    '--primary-background': '#fdfdfd',
    '--secondary-background': '#e0e0e0',
    '--tertiary-background': '#fafafa',

    '--primary-background-hover': '#cccccc',
    '--secondary-background-hover': '#cccccc',
    '--tertiary-background-hover': '#cccccc',

    '--task-running-denotation': '#21CD32',
    '--task-paused-denotation': '#FAFF00',

    '--delete-denotation': '#FF0606',

    '--button-background': '#4A4258',

    '--primary-text': '#0f0f0f',
    '--secondary-text': ' #717171',

    '--popup-background-transparency': 'rgba(167, 167, 167, 0.5)',
    '--popup-background': '#ffffff',

    '--button-normal': '#cccace',
    '--button-normal-hover': ' #e9e9e9',

    '--button-delete': '#FF0606',
    '--button-delete-hover': '#d10707',

    '--task-running-background': '#ffffff',
    '--task-paused-background': '#f5f5f5',
    '--task-completed-background': '#e2e2e2',
    '--task-running-text': '#161616',
    '--task-paused-text': '#626262',
    '--task-completed-text': '#232323',
    '--task-border': '#ececec',
    '--task-hover': '#ececec',
  },
  dark: {
    '--primary-background': '#000000',
    '--secondary-background': '#121212',
    '--tertiary-background': '#3B3546',

    '--primary-background-hover': '#1b073e',
    '--secondary-background-hover': '#30283e',
    '--tertiary-background-hover': '#3f3c45',

    '--task-running-denotation': '#21CD32',
    '--task-paused-denotation': '#FAFF00',

    '--delete-denotation': '#FF0606',

    '--button-background': '#4A4258',

    '--primary-text': '#DBDBDB',
    '--secondary-text': ' #8F8F8F',

    '--popup-background-transparency': 'rgb(43,33,61, 0.5)',
    '--popup-background': '#2B213D',

    '--button-normal': '#4A4258',
    '--button-normal-hover': ' #403551',

    '--button-delete': '#FF0606',
    '--button-delete-hover': '#d10707',

    '--task-running-background': '#231f29',
    '--task-paused-background': '#231f29',
    '--task-completed-background': '#231f29',
    '--task-running-text': '#DBDBDB',
    '--task-paused-text': '#989898',
    '--task-completed-text': '#DBDBDB',
    '--task-border': '#3B3546',
    '--task-hover': '#3B3546',
  },
};
