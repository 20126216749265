/* eslint-disable no-new */
import { useEffect, useState } from 'react';
import { fetchFromLocalStorage } from '../utils/storageUtils';

const activeNotifications = {};
export const notifiactionModes = {
  add: 'add',
  remove: 'remove',
  update: 'update',
  clearAll: 'clearAll',
};

export default () => {
  const notificationTime = fetchFromLocalStorage({ id: 'activityTimer' }) || 5;

  const [notiStatus, setNotiStatus] = useState(false);

  const askForPermisssion = () => {
    if (!notiStatus) {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          setNotiStatus(true);
        }
      });
    }
  };

  const clearAllNotifications = () => {
    Object.keys(activeNotifications).forEach((n) => {
      clearTimeout(activeNotifications[n]);
      delete activeNotifications[n];
    });
  };

  const handleNotification = (e) => {
    askForPermisssion();
    const { task, mode } = e.detail;
    const addNotification = () => {
      activeNotifications[task.id] = setTimeout(() => {
        const today = new Date();
        const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
        new Notification(`Hey Are you still working on "${task.name}"`, {
          body: `Please spare a second to open Timeox to continue tracking. Else the task "${task.name}" will be paused within 1 min from now. [Notified on - ${time}.]`,
          requireInteraction: true,
        });
        clearTimeout(activeNotifications[task.id]);
      }, notificationTime * 60 * 1000);
    };

    if (mode === notifiactionModes.add) {
      addNotification();
    } else if (mode === notifiactionModes.remove) {
      // remove
    } else if (mode === notifiactionModes.update) {
      // update
    } else if (mode === notifiactionModes.clearAll) {
      clearAllNotifications();
    }
  };

  useEffect(() => {
    window.addEventListener('notification', (e) => handleNotification(e));
    return () => {
      window.removeEventListener('notification', (e) => handleNotification(e));
    };
  }, []);
  useEffect(askForPermisssion, []);
};
