import { useEffect } from 'react';
import { fetchFromLocalStorage, saveToLocalStorage } from '../utils/storageUtils';

export default () => {
  useEffect(() => {
    const localTaskData = fetchFromLocalStorage({ id: 'tasks' });
    if (!localTaskData) {
      saveToLocalStorage({ id: 'tasks', data: { taskData: {}, version: 1 } });
    } else if (!localTaskData.version) {
      saveToLocalStorage({ id: 'tasks', data: { taskData: { ...localTaskData.taskData }, version: 1 } });
    }
  }, []);
};
