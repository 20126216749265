import { fetchFromLocalStorage, saveToLocalStorage } from '../../../utils/storageUtils';

export default (params) => {
  const {
    setTriggerRerender,
    setFilteredState,
    setLsState,
    lsState,
    triggerRerender,
    isCompletedTask,
  } = params;

  /* ----- Initial setup : fetch LS and save to LS state as such ----- */
  const initSyncLsToState = () => {
    const LSdata = fetchFromLocalStorage({ id: 'tasks' }) || {};
    setLsState(LSdata.taskData || {});
  };

  /* ----- Filter active tasks from LS state ----- */
  const filterTasks = ({ mode, tasks } = {}) => {
    const filteredTasks = {};
    let activeTaskModes = ['runningTask', 'pausedTask'];
    if (isCompletedTask) {
      activeTaskModes = ['completedTask'];
    }
    if (mode) {
      activeTaskModes = [mode];
    }
    const taskList = tasks || lsState;
    const lsKeys = Object.keys(taskList);
    if (lsKeys && lsKeys.length) {
      lsKeys.forEach((t) => {
        if (activeTaskModes.includes(taskList[t].mode)) {
          filteredTasks[t] = taskList[t];
        }
      });
    }
    return filteredTasks;
  };

  /* ----- Update the filtered data used for rendering with latest LS state ----- */
  const updateFilteredData = () => {
    setFilteredState(filterTasks());
  };

  /* ----- Sync the current LS copy state into LS ----- */
  const syncStateToLS = () => {
    const currentLS = fetchFromLocalStorage({ id: 'tasks' });
    if (triggerRerender !== 1) { // Because, no need for this action on first render
      saveToLocalStorage({ id: 'tasks', data: { ...currentLS, taskData: lsState } });
    }
  };

  /* ----- Trigger a rerender ----- */
  const rerenderComponent = () => {
    setTriggerRerender((s) => s + 1);
  };

  /* ------------------------------------------------------------------------ */
  return {
    initSyncLsToState,
    filterTasks,
    updateFilteredData,
    syncStateToLS,
    rerenderComponent,
  };
};
