import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { defaultTheme } from '../../../../config/theme.config';
import CompletedTasks from '../../../../data/icons/CompletedTask';
import Pomodoro from '../../../../data/icons/Pomodoro';
import Settings from '../../../../data/icons/Settings';
import TimeTrackingIcon from '../../../../data/icons/TimeTrackingIcon';
import themeColors from '../../../../themes/themeColor.constant.json';
import { getLabel } from '../../../../utils/localesUtils';
import { fetchFromLocalStorage } from '../../../../utils/storageUtils';
import styles from './styles.module.css';

export default (props) => {
  const { menu, header, toggleMenu } = props;
  const history = useHistory();
  const menuItemClass = [styles.menuItemWrapper];
  const isSelected = history.location.pathname === menu.url;
  const theme = fetchFromLocalStorage({ id: 'theme' }) || defaultTheme;
  const themeColor = themeColors[theme];
  if (isSelected) {
    menuItemClass.push(styles.focused);
  }
  if (header) {
    menuItemClass.push(styles.hearedMenuStyle);
  }
  return (
    <Link to={`${menu.url}`} onClick={toggleMenu}>
      <div className={menuItemClass.join(' ')}>
        {menu.menuId === 'time_tracking' ? <TimeTrackingIcon fill={isSelected ? themeColor.activeIcon : themeColor.normalIcon} /> : null}
        {menu.menuId === 'completed_tasks' ? <CompletedTasks fill={isSelected ? themeColor.activeIcon : themeColor.normalIcon} /> : null}
        {menu.menuId === 'settings' ? <Settings fill={isSelected ? themeColor.activeIcon : themeColor.normalIcon} /> : null}
        {menu.menuId === 'pomodoro' ? <Pomodoro fill={isSelected ? themeColor.activeIcon : themeColor.normalIcon} /> : null}
        <h3 className={isSelected ? styles.selected : undefined}>{getLabel(menu.localeKey)}</h3>
      </div>
    </Link>

  );
};
