import React, { useEffect, useState } from 'react';
import ActiveTask from './activeTask';
import AddNewTask from './addNewTask';
import styles from './styles.module.css';

const Task = (props) => {
  const {
    mode = 'newTask', addFunction = () => {}, index = 0, data = {},
    deleteTask, resumeTask, pauseTask, completeTask, restartTask,

  } = props;

  const [wrapperStyles, setWrapperStyles] = useState([styles.wrapperStyles, styles[`wrapperStyle_${mode}`]]);
  const statusIndicatorStyles = [styles.statusIndicatorStyle, styles[`statusIndicatorStyle_${mode}`]];
  const taskViewStyles = [styles.taskViewStyles, styles[`taskViewStyles_${mode}`]];

  useEffect(() => {
    setTimeout(() => {
      setWrapperStyles((s) => [...s, styles.wrapperFullStyle]);
    }, 50 * index);
  }, []);

  return (
    <div className={wrapperStyles.join(' ')}>
      <div className={statusIndicatorStyles.join(' ')} />
      <div className={taskViewStyles.join(' ')}>
        {mode === 'newTask' ? <AddNewTask addFunction={addFunction} />
          : (
            <ActiveTask
              index={index}
              data={data}
              deleteTask={deleteTask}
              resumeTask={resumeTask}
              pauseTask={pauseTask}
              completeTask={completeTask}
              restartTask={restartTask}
            />
          )}
      </div>

    </div>
  );
};

export default React.memo(Task);
