/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { trackEvent } from '../../../../controllers/analytics.controller';
import { addAnimation, removeAnimation } from '../../../../controllers/useAnimation.controller';
import useEventListner from '../../../../controllers/useEventListner.controller';
import { isInViewport } from '../../../../utils/commonUtils';
import { triggerShowQuickAdd } from '../../../../utils/customEventUtils';
import { onEnterPress } from '../../../../utils/keyUtils';
import styles from './styles.module.css';

const AddNewTask = (props) => {
  const { addFunction = () => {} } = props;
  const addTaskIcon = `${process.env.PUBLIC_URL}/assets/images/add_task.svg`;
  const [acceptInput, setAcceptInput] = useState(false);

  const inputRef = useRef();

  const [inputStyles, setInputStyles] = useState([styles.inputStyle]);
  const [inputValue, setInputValue] = useState('');

  const handleOnClick = () => {
    addAnimation({
      delay: 0,
      setStyle: setInputStyles,
      newStyles: [styles.inputStyleFull],
      setState: setAcceptInput,
    });
    setTimeout(() => {
      inputRef.current.focus();
    });
  };

  const addTaskAndReset = () => {
    inputRef.current.focus();
    removeAnimation({
      delay: 100,
      setStyle: setInputStyles,
      newStyles: [styles.inputStyleFull],
      setState: setAcceptInput,
      defaultStyles: [styles.inputStyle],
    });
    const inputV = inputValue.trim();
    if (inputV.length) {
      addFunction({
        name: inputV, mode: 'runningTask', addedTime: new Date().getTime(), updates: [{ resumed: new Date().getTime() }],
      });
    }
    setInputValue('');
  };

  const handleMouseClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (acceptInput && path && !path.includes(inputRef.current)) {
      addTaskAndReset();
      trackEvent({
        action: 'New task creation - Aborted',
        category: 'Task tracking',
        'Task title': 'NA',
        'Task ID': 'NA',
        value: 0,
      });
    }
  };

  useEventListner({ onClick: handleMouseClick, dependencies: [acceptInput, inputValue] });

  const renderText = () => (
    <div className={styles.titleWrapper}>
      <img className={styles.addNewTaskImage} src={addTaskIcon} alt="Add new task logo" />
      <h3 className={styles.addNewTaskTitle}>
        Start new task
      </h3>
      <div className={styles.tootltip}>Shortcut: Alt + N</div>
    </div>
  );

  const onPageScroll = () => {
    triggerShowQuickAdd(!isInViewport(document.getElementById('addNewTask')));
  };

  useEventListner({ event: 'scroll', target: document, onClick: onPageScroll });

  const altPressed = useRef(null);
  const handleKeyDown = (e) => {
    // 18 78
    if (e.keyCode === 18) {
      altPressed.current = true;
    } else if (e.keyCode === 78 && altPressed.current) {
      altPressed.current = null;
      handleOnClick();
    }
  };

  useEventListner({ event: 'keydown', onClick: handleKeyDown });
  useEventListner({ event: 'keyup', onClick: () => { altPressed.current = null; } });

  return (
    <div id="addNewTask" className={styles.addNewTaskWrapper} onClick={handleOnClick} onKeyDown={(event) => onEnterPress({ event, callback: addTaskAndReset })} role="none">
      {acceptInput
        ? <input className={inputStyles.join(' ')} value={inputValue} onChange={(e) => setInputValue(e.target.value)} ref={inputRef} type="text" />
        : renderText()}
    </div>
  );
};

export default AddNewTask;
