/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useEventListner from '../../../controllers/useEventListner.controller';
import './popup.css';
import popUpData from './popup.data';

const PortfolioPopup = () => {
  const history = useHistory();
  const { hash } = history.location;
  const data = popUpData[hash];
  const wrapperRef = useRef(null);

  const handleMouseClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (data && path && !path.includes(wrapperRef.current)) {
      history.push('/');
      e.stopPropagation();
    }
  };

  useEventListner({ onClick: handleMouseClick, dependencies: [data] });

  if (!data) {
    return null;
  }

  return (
    <div className="popup-surrounding">
      <div className="popup-wrapper" ref={wrapperRef}>
        <div className="popup-header">
          <div className="popup-title">{data.title}</div>
          <div className="popup-close-button" onClick={() => history.push('/')}>Close</div>
        </div>
        <div className="popup-content-wrapper" dangerouslySetInnerHTML={{ __html: data.data }}>
          {/* <div className="popup-content"> */}
          {/* {data.data} */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default PortfolioPopup;
