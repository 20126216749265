import { notifiactionModes } from '../controllers/notification.controller';

export const triggerEveryMinuteEvent = () => {
  const newEvent = new CustomEvent('everyMinute', {
    bubbles: false,
  });
  document.dispatchEvent(newEvent);
};

export const triggerEverySecEvent = () => {
  const newEvent = new CustomEvent('everySecond', {
    bubbles: false,
  });
  document.dispatchEvent(newEvent);
};

export const triggerThemeChange = (themeName) => {
  const newEvent = new CustomEvent('themeChange', {
    bubbles: false,
    detail: { themeName },
  });
  window.dispatchEvent(newEvent);
};

export const triggerShowQuickAdd = (visibility) => {
  const newEvent = new CustomEvent('showQuickAdd', {
    bubbles: false,
    detail: { visibility },
  });
  window.dispatchEvent(newEvent);
};

/* ---- Notification events ------ */
export const addNotification = (task) => {
  const newEvent = new CustomEvent('notification', {
    bubbles: false,
    detail: { task, mode: notifiactionModes.add },
  });
  window.dispatchEvent(newEvent);
};

export const removeNotification = (task) => {
  const newEvent = new CustomEvent('notification', {
    bubbles: false,
    detail: { task, mode: notifiactionModes.remove },
  });
  window.dispatchEvent(newEvent);
};

export const updateNotification = (task) => {
  const newEvent = new CustomEvent('notification', {
    bubbles: false,
    detail: { task, mode: notifiactionModes.update },
  });
  window.dispatchEvent(newEvent);
};

export const clearAllNotification = (task) => {
  const newEvent = new CustomEvent('notification', {
    bubbles: false,
    detail: { task, mode: notifiactionModes.clearAll },
  });
  window.dispatchEvent(newEvent);
};
/* ------------------------------- */
