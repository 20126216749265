import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Portfolio from '../../components/page/portfolio/index';
import routes from '../../constants/routes.json';
import BaseComponent from './BaseCompoenent';

const Routing = () => (
  <Router>
    <Switch>
      <Route path={routes.root} exact render={() => <Portfolio />} />
      {/* ------- Below is the APP base url ---------------------- */}
      <Route path={routes.appBase} render={() => <BaseComponent />} />
    </Switch>
  </Router>
);

export default Routing;
