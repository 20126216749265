import useStatusUpdate from './activeStatus.controller';
import useAnalyticsController from './analytics.controller';
import localDataController from './localData.controller';
import useNotificationController from './notification.controller';
import useTimeController from './timer.controller';

export default () => {
  useStatusUpdate();
  useTimeController();
  localDataController();
  useAnalyticsController();
  useNotificationController();
};
