import React from 'react';
import useBackgroundController from './controllers/useBackground.controller';
import Routing from './core/routing';

const App = () => {
  useBackgroundController();

  return (
    <div className="main-app-wrapper" style={{ width: '100%', minHeight: '100vh', display: 'flex' }}>
      <Routing />
    </div>
  );
};

export default App;
