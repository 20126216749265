import React, { useState } from 'react';
import { getLabel } from '../../../utils/localesUtils';
import { fetchFromLocalStorage, saveToLocalStorage } from '../../../utils/storageUtils';
import Button from '../../commonComponents/button';
import styles from './styles.module.css';

export default () => {
  // const themeList = Object.keys(themes);
  const [activityTimer, setActivityTimer] = useState(fetchFromLocalStorage({ id: 'activityTimer' }) || 5);
  const [showReloadButton, setShowReloadButton] = useState(false);

  const handleOnChange = (e) => {
    setActivityTimer(e.target.value);
    setShowReloadButton(true);
  };

  return (
    <>
      <h3 className={styles.themeSelection}>
        {getLabel('settings_activityTimerTitle')}
      </h3>
      <input type="number" id="quantity" value={activityTimer} name="quantity" min="1" onChange={handleOnChange} />
      {showReloadButton ? <Button text="Reload to appply changes." onClick={() => { saveToLocalStorage({ id: 'activityTimer', data: activityTimer }); window.location.href = window.location.pathname; }} /> : null}

    </>
  );
};
