/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes.json';
import { usePageViewTracker } from '../../../controllers/analytics.controller';
import Task from '../../commonComponents/task/index';
import actionsFunctions from './actions.functions';
import helperFucntions from './helper.fucntions';
import useNotificationDispatch from './notificationDispatch';
import QuickAddTask from './QuickAddTask';
import styles from './styles.module.css';

const TaskTracking = () => {
  const [lsState, setLsState] = useState({});
  const [filteredState, setFilteredState] = useState(null);
  const [triggerRerender, setTriggerRerender] = useState(1);
  const history = useHistory();
  const isCompletedTask = history.location.pathname === routes.completedTasks;

  const {
    initSyncLsToState,
    filterTasks,
    updateFilteredData,
    syncStateToLS,
    rerenderComponent,
  } = helperFucntions({
    setTriggerRerender,
    setFilteredState,
    setLsState,
    lsState,
    triggerRerender,
    isCompletedTask,
  });

  const {
    addNewTask,
    deleteTask,
    pauseTask,
    resumeTask,
    completeTask,
    restartTask,
  } = actionsFunctions({
    lsState,
    setLsState,
    rerenderComponent,
  });

  useNotificationDispatch({ tasks: lsState, filterTasks });
  usePageViewTracker(history.location.pathname);
  useEffect(initSyncLsToState, [history.location.pathname]);
  useEffect(updateFilteredData, [lsState, triggerRerender]);
  useEffect(syncStateToLS, [triggerRerender]);

  const filteredStateKeys = filteredState && Object.keys(filteredState);

  return (
    <>
      <div id="tasks-bg" className={styles.taskTrackingBackground}>
        {(triggerRerender && filteredStateKeys && filteredStateKeys.length) ? filteredStateKeys.map((t) => (
          <Task
            mode={filteredState[t].mode}
            key={t}
            index={t}
            data={filteredState[t]}
            addFunction={addNewTask}
            deleteTask={deleteTask}
            resumeTask={resumeTask}
            completeTask={completeTask}
            pauseTask={pauseTask}
            restartTask={restartTask}
          />
        )) : null }
        {isCompletedTask ? null : <Task mode="newTask" addFunction={addNewTask} />}
        {/* <Task mode="newTask" addFunction={addNewTask} deleteTask={deleteTask} resumeTask={resumeTask} pauseTask={pauseTask} /> */}
        <QuickAddTask />
      </div>
    </>
  );
};

export default TaskTracking;
