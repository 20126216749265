export const addAnimation = ({
  delay = 500, newStyles = [], setStyle = null, setState = null,
}) => {
  if (setState) {
    setState(true);
  }
  if (setStyle) {
    setTimeout(() => {
      setStyle((s) => [...s, ...newStyles]);
    }, delay);
  }
};
export const removeAnimation = ({
  delay = 500, setStyle = null, defaultStyles = [], setState = null,
}) => {
  if (setStyle) {
    setStyle([...defaultStyles]);
  }
  if (setState) {
    setTimeout(() => {
      setState(false);
    }, delay);
  }
};
