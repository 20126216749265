/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../../components/commonComponents/header';
import Sidebar from '../../components/commonComponents/sidebarMenu';
import Settings from '../../components/page/settings';
import TaskTracking from '../../components/page/taskTracking';
import { defaultTheme } from '../../config/theme.config';
import routes from '../../constants/routes.json';
import themes from '../../themes/theme';
import { fetchFromLocalStorage, saveToLocalStorage } from '../../utils/storageUtils';
import styles from './styles.module.css';

const BaseComponent = () => {
  const [activeTheme, setActiveTheme] = useState(fetchFromLocalStorage({ id: 'theme' }) || defaultTheme);

  useEffect(() => {
    const handleThemeChange = (e) => {
      setActiveTheme(e.detail.themeName);
      saveToLocalStorage({ id: 'theme', data: e.detail.themeName });
    };
    window.addEventListener('themeChange', handleThemeChange);
    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  return (
    <div style={themes[activeTheme]} className={styles.appWrapper}>
      <div className={styles.baseComponentSidebar}>
        <Sidebar />
      </div>
      <div className={styles.baseComponentContent}>
        <Header />
        <Switch>
          <Route path={routes.timeTracker} render={() => <TaskTracking />} />
          <Route path={routes.completedTasks} render={() => <TaskTracking />} />
          <Route path={routes.settings} render={() => <Settings />} />
        </Switch>
        {/* <BaseAdvertisement /> */}
      </div>

    </div>
  );
};

export default BaseComponent;
