// /* eslint-disable no-unused-vars */
import React from 'react';
import { usePageViewTracker } from '../../../controllers/analytics.controller';
import ActivityTracking from './ActivityTracking';
import styles from './styles.module.css';
import ThemeSelection from './ThemeSelection';

export const notificationPauseDeplay = 1; // in ms

const TaskTracking = () => {
  usePageViewTracker('Settings Page');
  return (
    <>
      <div className={styles.settingsWrapper}>
        <ThemeSelection />
        <ActivityTracking />
      </div>

    </>
  );
};

export default TaskTracking;
