import { trackEvent } from '../../../controllers/analytics.controller';
import { generateRandomString } from '../../../utils/commonUtils';

export default (params) => {
  const {
    lsState,
    setLsState,
    rerenderComponent,
  } = params;

  const pauseAllTasksExceptOne = (id) => {
    trackEvent({
      action: 'Task paused except one',
      category: 'Task tracking',
      'Task title': 'NA',
      'Task ID': 'NA',
      value: 'NA',
    });
    setLsState((s) => {
      const tempS = s;
      Object.keys(tempS).forEach((t) => {
        if (t !== id && tempS[t].mode === 'runningTask') {
          tempS[t].mode = 'pausedTask';
          tempS[t].updates = [...(tempS[t].updates || []), { paused: new Date().getTime() }];
        }
      });
      return tempS;
    });
  };

  const addNewTask = (task) => {
    const newTaskObj = {};
    const randomString = generateRandomString();
    newTaskObj[randomString] = task;
    newTaskObj[randomString].id = randomString;
    trackEvent({
      action: 'New task creation',
      category: 'Task tracking',
      'Task title': task.name,
      'Task ID': randomString,
      value: 0,
    });
    setLsState((s) => ({ ...s, ...newTaskObj }));
    pauseAllTasksExceptOne(randomString);
    rerenderComponent();
  };

  const deleteTask = (id) => {
    trackEvent({
      action: 'Task deleted',
      category: 'Task tracking',
      'Task title': lsState[id].name,
      'Task ID': id,
      value: 0,
    });
    const newObj = lsState;
    delete lsState[id];
    setLsState((s) => ({ ...s, ...newObj }));
    rerenderComponent();
  };

  const pauseTask = (id, time) => {
    trackEvent({
      action: 'Task paused',
      category: 'Task tracking',
      'Task title': lsState[id].name,
      'Task ID': id,
      value: 0,
    });
    const newObj = lsState;
    newObj[id].mode = 'pausedTask';
    newObj[id].updates = [...(newObj[id].updates || []), { paused: time || new Date().getTime() }];
    setLsState((s) => ({ ...s, ...newObj }));
    rerenderComponent();
  };

  const resumeTask = (id) => {
    trackEvent({
      action: 'Task resumed',
      category: 'Task tracking',
      'Task title': lsState[id].name,
      'Task ID': id,
      value: 0,
    });
    const newObj = lsState;
    newObj[id].mode = 'runningTask';
    newObj[id].updates = [...(newObj[id].updates || []), { resumed: new Date().getTime() }];
    setLsState((s) => ({ ...s, ...newObj }));
    rerenderComponent();
  };

  const completeTask = (id) => {
    trackEvent({
      action: 'Task completed',
      category: 'Task tracking',
      'Task title': lsState[id].name,
      'Task ID': id,
      value: 0,
    });
    const newObj = lsState;
    if (newObj[id].mode === 'pausedTask') {
      newObj[id].updates = [...(newObj[id].updates || []), { resumed: new Date().getTime() }];
    }
    newObj[id].updates = [...(newObj[id].updates || []), { completed: new Date().getTime() }];
    newObj[id].mode = 'completedTask';
    setLsState((s) => ({ ...s, ...newObj }));
    rerenderComponent();
  };
  const restartTask = (id) => {
    trackEvent({
      action: 'Task restart',
      category: 'Task tracking',
      'Task title': lsState[id].name,
      'Task ID': id,
      value: 0,
    });
    const newObj = lsState;
    newObj[id].mode = 'runningTask';
    newObj[id].updates = [...(newObj[id].updates || []), { resumed: new Date().getTime() }];
    setLsState((s) => ({ ...s, ...newObj }));
    rerenderComponent();
  };

  /* -------------------------------------------------  */
  return {
    addNewTask,
    deleteTask,
    pauseTask,
    resumeTask,
    completeTask,
    restartTask,
  };
};
