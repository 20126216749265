/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import SidebarMenuConfig from '../../../config/sidebarMenu.json';
import { defaultTheme } from '../../../config/theme.config';
import BrandingLogo from '../../../data/icons/BrandingLogo';
import themeColors from '../../../themes/themeColor.constant.json';
import { fetchFromLocalStorage } from '../../../utils/storageUtils';
import MenuItem from '../sidebarMenu/MenuItem';
import styles from './styles.module.css';

const Header = () => {
  // const brandingLogoSrc = `${process.env.PUBLIC_URL}/assets/images/branding_logo.svg`;
  const hamburgerMenuSrc = `${process.env.PUBLIC_URL}/assets/images/hamburger_menu.svg`;
  const closeMenuSrc = `${process.env.PUBLIC_URL}/assets/images/close_menu.svg`;

  const theme = fetchFromLocalStorage({ id: 'theme' }) || defaultTheme;
  const themeColor = themeColors[theme];

  const [menuEnabled, setMenuEnabled] = useState(false);
  const menuDisabledStyles = [styles.menuWrapper];
  const menuEnabledStyles = [styles.menuWrapper, styles.menuWrapperEnabled];

  const toggleMenu = () => setMenuEnabled((s) => !s);

  return (
    <>
      <div className={styles.headerWrapper}>
        <img
          className={styles.hamburgerMenu}
          src={menuEnabled ? closeMenuSrc : hamburgerMenuSrc}
          alt="hamburger menu"
          onClick={toggleMenu}
        />
        <BrandingLogo fill={themeColor.brandingLogoColor} />
        {/* <div className={styles.brandingName}>
          <div className={styles.productName}>Time Tracker</div>
          <div className={styles.companyName}>Helixatom</div>
        </div> */}
        <div className={menuEnabled ? menuEnabledStyles.join(' ') : menuDisabledStyles.join(' ')}>
          {SidebarMenuConfig.map((menu) => <MenuItem header toggleMenu={toggleMenu} key={menu.menuId} menu={menu} />) }

        </div>
      </div>

    </>
  );
};

export default Header;
