import { useEffect } from 'react';

export default () => {
  const initilizeGoogleAnalytics = () => {
    window.googleAnalyticsCustom.gtag('config', 'G-L3Y8H7RF9S', { send_page_view: false });
    window.googleAnalyticsCustom.gtag('js', new Date());
  };
  useEffect(initilizeGoogleAnalytics, []);
};

export const usePageViewTracker = (data = window.location.href) => {
  const triggerPageView = () => {
    window.googleAnalyticsCustom.gtag('event', 'Page Visit', { page: data });
  };
  useEffect(triggerPageView, []);
};

export const trackEvent = (d) => {
  const data = d;
  const {
    action = 'Unnamed Action',
  } = data || {};
  delete data.action;
  window.googleAnalyticsCustom.gtag('event', action, data);
};
