/* eslint-disable */
import React from 'react';

const TimeTrackingIcon = ({ fill }) => (
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="44.466px" height="44.466px" viewBox="0 0 44.466 44.466" fill={fill}
	 xml="preserve">
<g>
	<g>
		<path d="M22.233,0C9.974,0,0,9.974,0,22.232c0,12.26,9.975,22.233,22.233,22.233c12.257,0,22.233-9.973,22.233-22.233
			C44.466,9.973,34.492,0,22.233,0z M22.233,37.293c-8.305,0-15.061-6.756-15.061-15.061c0-8.303,6.756-15.06,15.061-15.06
			c8.304,0,15.06,6.756,15.06,15.06C37.293,30.537,30.537,37.293,22.233,37.293z"/>
		<path d="M23.5,9.42c-0.324-0.031-0.646,0.075-0.887,0.294c-0.241,0.219-0.38,0.529-0.38,0.854V21.08
			c0,0.637,0.516,1.152,1.153,1.152h10.513c0.325,0,0.637-0.137,0.854-0.378c0.218-0.241,0.323-0.563,0.292-0.887
			C34.451,14.867,29.6,10.016,23.5,9.42z"/>
		<circle cx="22.233" cy="33.38" r="2.442"/>
		<circle cx="11.255" cy="22.443" r="2.443"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>


);

export default TimeTrackingIcon;
