/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */

export const keyCodes = {
  enter: 13,
};
export const onEnterPress = ({ event = {}, callback = () => {} }) => {
  if (event.keyCode === keyCodes.enter) {
    callback();
  }
};
