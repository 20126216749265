/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from '../../../constants/routes.json';
import useEventListner from '../../../controllers/useEventListner.controller';
import styles from './quickAdd.module.css';

export default () => {
  const [showQuickAdd, setShowQuickAdd] = useState(false);
  const history = useHistory();
  const addTaskIcon = `${process.env.PUBLIC_URL}/assets/images/add_task.svg`;

  const quickAddStatus = (e) => {
    setShowQuickAdd(e.detail.visibility);
  };

  const handleQuickAddClick = () => {
    const elem = document.getElementById('addNewTask');
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };

  /* -- Short cur - Add new task ----- */
  const altPressed = useRef(null);
  const handleKeyDown = (e) => {
    if (e.keyCode === 18) {
      altPressed.current = true;
    } else if (e.keyCode === 78 && altPressed.current) {
      altPressed.current = null;
      handleQuickAddClick();
    }
  };

  useEventListner({ event: 'showQuickAdd', onClick: quickAddStatus });
  useEventListner({ event: 'keydown', onClick: handleKeyDown });
  useEventListner({ event: 'keyup', onClick: () => { altPressed.current = null; } });

  const [isTaskTracking, setIsTaskTracking] = useState(null);

  useEffect(() => {
    setIsTaskTracking(history.location.pathname === Routes.timeTracker);
  }, [history.location.pathname, setIsTaskTracking]);

  if (!showQuickAdd || !isTaskTracking) {
    return null;
  }
  return (
    <div className={styles.quickAddWrapper} onClick={handleQuickAddClick}>
      <img className={styles.addNewTaskImage} src={addTaskIcon} alt="Add new task logo" />
    </div>
  );
};
