import { useEffect, useRef } from 'react';
import { triggerEveryMinuteEvent, triggerEverySecEvent } from '../utils/customEventUtils';

export default () => {
  const timer = useRef(0);
  const counter = useRef(0);
  useEffect(() => {
    timer.current = setInterval(() => {
      triggerEverySecEvent();
      if (counter.current >= 60) {
        counter.current = 0;
        triggerEveryMinuteEvent();
      } else {
        counter.current += 1;
      }
    }, 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, []);
};
