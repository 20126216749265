/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory } from 'react-router-dom';
import SidebarMenuConfig from '../../../config/sidebarMenu.json';
import { defaultTheme } from '../../../config/theme.config';
import routes from '../../../constants/routes.json';
import BrandingLogo from '../../../data/icons/BrandingLogo';
import HelixAtomBrandingLogo from '../../../data/icons/HelixAtomBrandingLogo';
import themeColors from '../../../themes/themeColor.constant.json';
import { fetchFromLocalStorage } from '../../../utils/storageUtils';
import MenuItem from './MenuItem';
import styles from './styles.module.css';

const SidebarMenu = () => {
  const history = useHistory() || {};
  const sidebarRoutes = [routes.root, routes.timeTracker, routes.completedTasks, routes.settings];
  const { location: { pathname = '' } = {} } = history;

  const theme = fetchFromLocalStorage({ id: 'theme' }) || defaultTheme;
  const themeColor = themeColors[theme];

  if (sidebarRoutes.indexOf(pathname) === -1) {
    return null;
  }

  return (
    <div className={styles.sidebarPlaceholder}>
      <div className={styles.headerWrapper}>
        <div className={styles.branding} role="button" onClick={() => history.push('/')}>
          <BrandingLogo fill={themeColor.brandingLogoColor} />
        </div>
        {SidebarMenuConfig.map((menu) => <MenuItem key={menu.menuId} menu={menu} />) }
        <div className={styles.headerFooter}>
          <HelixAtomBrandingLogo fill={themeColor.brandingLogoColor} />
          {' '}
          <span>PRODUCTIVITY</span>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
