import React from 'react';
import styles from './styles.module.css';

const Button = (props) => {
  const {
    text = '',
    onClick = () => {},
    classNames = [],
    className = '',
    type = null,
  } = props;

  const buttonClasess = [styles.button, ...classNames, className];
  if (type) {
    buttonClasess.push(styles[type]);
  }

  return (<button type="button" className={buttonClasess.join(' ')} onClick={onClick}>{text}</button>);
};

export default Button;
