export const formatToHHMMSS = (p) => {
  let ms = p / 1000;
  let h = 0;
  let m = 0;
  if (Math.floor(ms / 3600) > 0) {
    h = Math.floor(ms / 3600);
    ms %= 3600;
  }
  if (Math.floor(ms / 60) > 0) {
    m = Math.floor(ms / 60);
    ms %= 60;
  }

  const formatTime = (t) => {
    if (t > 9) {
      return t;
    }
    return `0${t}`;
  };

  return `${formatTime(h)}:${formatTime(m)}:${formatTime(Math.floor(ms))}`;
};

export const generateRandomString = () => `${Math.random()}_${Math.random()}`;

export const isInViewport = (element, df) => {
  if (element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0
      && rect.left >= 0
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  return df;
};
