import React from 'react';
import themes from '../../../themes/theme';
import { triggerThemeChange } from '../../../utils/customEventUtils';
import { getLabel } from '../../../utils/localesUtils';
import { fetchFromLocalStorage } from '../../../utils/storageUtils';
import styles from './styles.module.css';

export default () => {
  const themeList = Object.keys(themes);
  const currentTheme = fetchFromLocalStorage({ id: 'theme' });

  return (
    <>
      <h3 className={styles.themeSelection}>
        {getLabel('settings_themeSelectionTitle')}
      </h3>
      <select name="Themes" id="themeSelection" onChange={(p) => triggerThemeChange(p.target.value)}>
        {themeList.map((t) => <option selected={t === currentTheme} value={t}>{t}</option>)}
      </select>
    </>
  );
};
